import styles from '../styles.module.scss'

const copyAndPasteTools = {
  calendar: {
    name: 'Unicode calendar generator',
    text:
`╔════════════════════╗
║ November ░░░░ 2023 ║
╟──┬──┬──┬──┬──┬──┬──╢
║░░│░░│░░│01│02│03│04║
╟──┼──┼──┼──┼──┼──┼──╢
║05│06│07│08│09│10│11║
╟──╔══╗──┼──┼──┼──┼──╢
║12║13║14│15│16│17│18║
╟──╚══╝──┼──┼──┼──┼──╢
║19│20│21│22│23│24│25║
╟──┼──┼──┼──┼──┼──┼──╢
║26│27│28│29│30│░░│░░║
╚══╧══╧══╧══╧══╧══╧══╝`,
    className: styles.mono,
    link: '/calendar/'
  },
  horoscope: {
    name: 'Emoji horoscope',
    text: `♈️♉️♊️♋️♌️♍️♎️♏️♐️♑️♑️♒️♓️
Get your weekly horoscope in emoji form.`,
    link: '/emoji-horoscope/'
  },
  check: { name: 'Check marks ✔', text: 'Copy and paste check marks for to-do lists, quizzes, ballots, and more.', link: '/checkmarks/' },
  chess: {
    name: 'Chess symbols ♛ and Unicode chess board generator',
    text: 'Quickly copy and paste chess symbols and generate unique Unicode chess boards.',
    link: '/chess-symbols/'
  },
  bang: { name: 'Interrobang‽', text: 'Copy and paste the interrobang and other obscure end-marks.', link: '/interrobang/' },
  snow: {
    name: 'Snowflake symbols',
    text: 'Copy and paste Unicode snowflakes ❅ ❆ ❃ ❊ ❉ and other winter ☃️ and Christmas 🎅 symbols and emojis.',
    link: '/snowflake-text-symbols/'
  },
  star: {
    name: 'Star symbols',
    text: 'Copy and paste Unicode stars including Dingbat symbols ✰ pentagrams ⛤ religious stars ✡ and more. ✶ ✵ ✸ ✮ ✪',
    link: '/star-symbols/'
  },
  white: {
    name: 'Whitespace characters',
    text: 'Quickly copy and paste Unicode whitespace characters and learn how and when to use them.',
    link: '/whitespace/'
  }
}

const allToolsByShortName = {
  ...copyAndPasteTools,
  underline: {
    name: 'Underline generator',
    text: 'Use underlined text in social media and other places you\'re 𝚗̲𝚘̲𝚝̲ ̲𝚗̲𝚘̲𝚛̲𝚖̲𝚊̲𝚕̲𝚕̲𝚢̲ ̲𝚊̲𝚕̲𝚕̲𝚘̲𝚠̲𝚎̲𝚍̲.',
    link: '/underline-text/'
  },
  super: {
    name: 'Superscript generator',
    text: 'Use ˢᵘᵖᵉʳˢᶜʳᶦᵖᵗ everywhere online — including social media, email, browser tabs, and more.',
    link: '/superscript/'
  },
  sponge: {
    name: 'Spongebob text generator',
    text: 'Generate MoCkInG SpOnGeBoB text with alternating uppercase and lowercase letters, which is popular for memes, social media posts, and more.',
    link: '/spongebob/'
  },
  smallCaps: { name: 'Small caps generator', text: 'Quickly generate, copy, and paste Unicode Sᴍᴀʟʟ Cᴀᴘs.', link: '/small-caps/' },
  fancy: {
    name: 'Fancy font generator',
    text: 'Create 𝖋𝖆𝖓𝖈𝖞, 🄴🅈🄴-🄲🄰🅃🄲🄷🄸🄽🄶 text that works in social media posts, bios, instant messages, and more.',
    link: '/fancy/'
  },
  gothic: {
    name: 'Fraktur/Gothic/Blackletter',
    text: 'Copy and paste Gothic script letters to add a traditional look to text headings.',
    link: '/gothic/'
  },
  squared: {
    name: 'Squared font generator',
    text: 'Use 🆂🆀🆄🅰🆁🅴🅳 letters and numbers in emails, social media posts, and other places where you want to create eye-catching headers, bullet points, and text.',
    link: '/squared/'
  },
  circled: {
    name: 'Circled font generator',
    text: 'Use 🅒🅘🅡🅒🅛🅔🅓 letters and numbers in emails, social media posts, and other places where you want to create eye-catching headers, bullet points, and text.',
    link: '/circled/'
  },
  monospace: {
    name: 'Monospace/typewriter',
    text: 'Create text with a classic typewriter font in which every character has the same width.',
    link: '/typewriter'
  },
  outlined: {
    name: 'Double struck/outlined',
    text: 'Quickly generate, copy, and paste characters that are doubled up to create a unique bold font effect.',
    link: '/outlined/'
  },
  sans: {
    name: 'Sans serif font generator',
    text: 'Create text without extending features called "serifs" on each character and apply it anywhere online, including social media bios and posts, browser tabs, and more.',
    link: '/sans-serif/'
  },
  serif: {
    name: 'Serif font generator',
    text: 'Create text with extending features called "serifs" on each character and apply it anywhere online, including social media bios and posts, browser tabs, and more.',
    link: '/serif/'
  },
  upside: {
    name: 'Upside down text generator',
    text: 'Generate flipped text that works anywhere online and is easy to understand — even upside down.',
    link: '/upside-down-text/'
  },
  cursive: { name: 'Cursive text generator', text: 'Generate 𝓒𝓾𝓻𝓼𝓲𝓿𝓮 𝓣𝓮𝔁𝓽 that works anywhere on the web.', link: '/cursive/' },
  italic: {
    name: 'Italic text generator',
    text: 'Use italics in places you normally can\'t, like social media posts and bios, instant messages, and more.',
    link: '/italic/'
  },
  zalgo: { name: 'Zalgo text generator', text: 'Generate Z̽̂a͔ͮ̑ĺ̹̈́g̪̿ö́ text you can use anywhere online.', link: '/zalgo-text/', className: styles.monospace },
  vapor: {
    name: 'Vaporwave text generator',
    text: 'Generate【Ａｅｓｔｈｅｔｉｃ】full width text that works in online posts, bios, instant messages, and more.',
    link: '/vaporwave-text/'
  },
  back: {
    name: 'Backwards text generator',
    text: 'Generate reversed or mirrored text to use in social media posts, instant messages, browser tabs, and more.',
    link: '/backwards-text/'
  },
  strike: {
    name: 'Strikethrough generator',
    text: 'Create 𝚂̶𝚝̶𝚛̶𝚒̶𝚔̶𝚎̶𝚝̶𝚑̶𝚛̶𝚘̶𝚞̶𝚐̶𝚑̶, 𝚜̷𝚕̷𝚊̷𝚜̷𝚑̷𝚎̷𝚍̷, or ████████ text that works anywhere online, including social media posts and bios, emails, and lots more.',
    link: '/strikethrough/'
  },
  bold: { name: 'Bold text generator', text: 'A tool for creating 𝘽𝙤𝙡𝙙 𝙏𝙚𝙭𝙩 that works anywhere online.', link: '/bold-text/' }
}

const fancySeparatedTools = [
  allToolsByShortName.gothic,
  allToolsByShortName.squared,
  allToolsByShortName.circled,
  allToolsByShortName.monospace,
  allToolsByShortName.outlined,
  allToolsByShortName.sans,
  allToolsByShortName.serif
]

const unicode = Object.values(allToolsByShortName)

const tools = [
  ...unicode
]

const todo = [
  allToolsByShortName.check
]

const time = [
  allToolsByShortName.calendar
]

const text = [
  allToolsByShortName.underline,
  allToolsByShortName.super,
  allToolsByShortName.sponge,
  allToolsByShortName.smallCaps,
  allToolsByShortName.fancy,
  ...fancySeparatedTools,
  allToolsByShortName.upside,
  allToolsByShortName.cursive,
  allToolsByShortName.italic,
  allToolsByShortName.zalgo,
  allToolsByShortName.vapor,
  allToolsByShortName.back,
  allToolsByShortName.strike,
  allToolsByShortName.bold
]

const symbols = [
  allToolsByShortName.chess,
  allToolsByShortName.check,
  allToolsByShortName.bang,
  allToolsByShortName.star,
  allToolsByShortName.snow
]

const stars = [
  allToolsByShortName.star
]

const snowflakes = [
  allToolsByShortName.snow
]

const punctuation = [
  allToolsByShortName.bang,
  allToolsByShortName.white
]

const meta = [
  allToolsByShortName.underline,
  allToolsByShortName.super,
  allToolsByShortName.sponge,
  allToolsByShortName.smallCaps,
  allToolsByShortName.fancy,
  ...fancySeparatedTools,
  allToolsByShortName.upside,
  allToolsByShortName.cursive,
  allToolsByShortName.italic,
  allToolsByShortName.zalgo,
  allToolsByShortName.vapor,
  allToolsByShortName.back,
  allToolsByShortName.strike,
  allToolsByShortName.star,
  allToolsByShortName.snow,
  allToolsByShortName.bold
]

const memes = [
  allToolsByShortName.sponge,
  allToolsByShortName.horoscope,
  allToolsByShortName.chess,
  allToolsByShortName.star,
  allToolsByShortName.snow
]

const italics = [
  allToolsByShortName.underline,
  allToolsByShortName.fancy,
  allToolsByShortName.italic,
  allToolsByShortName.bold
]

const hacks = [
  allToolsByShortName.underline,
  allToolsByShortName.super,
  allToolsByShortName.sponge,
  allToolsByShortName.smallCaps,
  allToolsByShortName.fancy,
  ...fancySeparatedTools,
  allToolsByShortName.upside,
  allToolsByShortName.cursive,
  allToolsByShortName.italic,
  allToolsByShortName.zalgo,
  allToolsByShortName.vapor,
  allToolsByShortName.back,
  allToolsByShortName.strike,
  allToolsByShortName.bold,
  allToolsByShortName.white
]

const glitch = [
  allToolsByShortName.zalgo
]

const fonts = [
  allToolsByShortName.underline,
  allToolsByShortName.super,
  allToolsByShortName.sponge,
  allToolsByShortName.smallCaps,
  allToolsByShortName.fancy,
  ...fancySeparatedTools,
  allToolsByShortName.upside,
  allToolsByShortName.cursive,
  allToolsByShortName.italic,
  allToolsByShortName.zalgo,
  allToolsByShortName.vapor,
  allToolsByShortName.back,
  allToolsByShortName.strike,
  allToolsByShortName.bold
]

const emoji = [
  allToolsByShortName.sponge,

  allToolsByShortName.horoscope,
  allToolsByShortName.chess,
  allToolsByShortName.check,
  allToolsByShortName.star,
  allToolsByShortName.snow
]

const dingbats = [
  allToolsByShortName.check,
  allToolsByShortName.bang,
  allToolsByShortName.star,
  allToolsByShortName.snow
]

const date = [
  allToolsByShortName.calendar,
  allToolsByShortName.horoscope
]

const cursive = [
  allToolsByShortName.fancy,
  allToolsByShortName.cursive
]

const copyAndPaste = [
  allToolsByShortName.calendar,
  allToolsByShortName.horoscope,
  allToolsByShortName.chess,
  allToolsByShortName.check,
  allToolsByShortName.bang,
  allToolsByShortName.star,
  allToolsByShortName.snow,
  allToolsByShortName.white
]

const calendar = [
  ...date
]

type Variant = {
  name: string
  text: string
  link: string
  className?: string
}

export const cards: Record<string, Variant[]> = {
  unicode,
  tools,
  'to-do-lists': todo,
  time,
  text,
  symbols,
  stars,
  snowflakes,
  punctuation,
  meta,
  memes,
  italics,
  hacks,
  glitch,
  fonts,
  emoji,
  dingbats,
  date,
  cursive,
  'copy-and-paste': copyAndPaste,
  calendar
}
