import React from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'

import { Card } from '../../components/Card'
import { Discover } from '../../components/Discover'
import { WhyNamecheap } from '../../components/WhyNamecheap'
import { Header } from '../../components/Header'

import { cards } from './constants/tags'

import styles from './styles.module.scss'

const Tags: React.FC = () => {
  const { id } = useParams()

  if (!id) {
    return null
  }

  return (
    <div>
      <Header headerClassName={styles['header-image']} />
      <div className={styles['variants-container']}>
        {cards[id].map(generator => (
          <Card
            textWrap={true}
            className={cn(styles.card, generator.className)}
            key={generator.name}
            title={generator.name}
            text={generator.text}
            link={generator.link}
            textClassName={generator.className ? styles[generator.className] : styles.text}
            hideIcon
          />
        ))}
      </div>
      <Discover />
      <WhyNamecheap />
    </div>
  )
}

export default Tags
